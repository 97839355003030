import { format } from 'date-fns'
import { DATE_FORMAT } from '../date-formatting.js'
import { configApi } from '../http-client.js'

export const getApiUsers = () => {
	return configApi.get('/api/ApiUsers').then(({ data: { apiUsers } }) => ({
		apiUsers: apiUsers.map(apiUser => ({
			userId: apiUser.userId,
			username: apiUser.username,
			apiKeys: apiUser.apiKeys.map(apiKey => {
				return {
					apiKeyId: apiKey.keyId,
					apiKeyName: apiKey.keyName
				}
			}),
			brandIds: apiUser.brandIds,
			createdDate: format(new Date(apiUser.createdDate), DATE_FORMAT)
		}))
	}))
}

export const deleteApiUser = userId => {
	return configApi.delete(`/api/ApiUsers/${userId}`)
}
export const upsertApiUser = user => {
	const upsertUser = {
		userId: user.userId,
		brandIds: user.brandIds,
		username: user.username,
		apiKeyIds: user.apiKeyIds,
		password: user.password
	}
	return configApi.post('/api/ApiUsers/Upsert', upsertUser)
}
