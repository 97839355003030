<template>
	<AdminPortalApiUsersLayout>
		<template #content>
			<div class="cassie-vertical-md">
				<FilterBar
					:search-query.sync="systemUserTextSearchQuery"
					search-query-label="API Username"
					hide-brand-filter
					@persistSearchQuery="changeSystemUserTextSearchQuery"
					@clearFilters="clearFilters"
				>
					<template #after-filters>
						<Dropdown
							v-model="apiKeyNameFilter"
							label="API Key Name"
							:items="filterByApiKeyOptions"
							@input="changeApiKeyNameFilter($event)"
						/>
					</template>
					<template #action>
						<PrimaryActionButton
							v-if="fullPermission"
							@click="createApiUser"
						>
							<v-icon left>
								mdi-plus
							</v-icon>
							Create
						</PrimaryActionButton>
					</template>
				</FilterBar>
				<SectionCard>
					<template #title>
						API Users
					</template>
					<template #body>
						<DataTable
							:items="tableData"
							:headers="tableHeaders"
							sort-by="userId"
							sort-desc
							@click:row="editApiUser"
						>
							<template #item.action="{ item }">
								<IconButton
									:tooltip-text="fullPermission ? 'Edit API User' : 'View API User'"
									@click="editApiUser(item)"
								>
									{{ fullPermission ? 'mdi-pencil' : 'mdi-eye' }}
								</IconButton>
								<IconButton
									v-if="fullPermission"
									tooltip-text="Delete API User"
									@click.stop.prevent="apiUserToRemove = item"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</template>
				</SectionCard>
				<ConfirmDeleteModal
					v-if="apiUserToRemove"
					:entity-name="apiUserToRemove.username"
					entity-type="API User"
					@close="apiUserToRemove = null"
					@delete="removeApiUser"
				/>
			</div>
		</template>
	</AdminPortalApiUsersLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import ConfirmDeleteModal from '../../../../../../../shared/components/confirm-delete-modal.vue'
import AdminPortalApiUsersLayout from './admin-portal-api-users-layout.vue'
import { brandOptions } from '../../../../../../../shared/state/brands.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { ADMIN_PORTAL_API_MANAGE_USER } from '../../../../../router/route-names.js'
import { deleteApiUser, getApiUsers } from '../../../../../../../shared/utils/api/api-users.js'
import { SYSTEM_USERS_MODULE_FULL_PERMISSIONS } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import {
	changeSystemUserTextSearchQuery,
	changeApiKeyNameFilter,
	systemUserTextSearchQuery,
	apiKeyNameFilter
} from '../../../../../../../shared/state/system-users.js'
export default {
	components: { AdminPortalApiUsersLayout, ConfirmDeleteModal, DataTable, SectionCard, Dropdown, PrimaryActionButton, FilterBar, IconButton },
	setup () {
		return {
			showSnackbar,
			changeSystemUserTextSearchQuery,
			changeApiKeyNameFilter,
			systemUserTextSearchQuery,
			apiKeyNameFilter
		}
	},
	data () {
		return {
			apiUserToRemove: null,
			apiUsers: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		tableHeaders () {
			return [
				{ value: 'userId', text: 'ID', width: '5%' },
				{ value: 'username', text: 'API Username', width: '30%' },
				{ value: 'apiKeyNames', text: 'API Key Name(s)', width: '35%' },
				{ value: 'createdDate', text: 'Created Date', width: '20%' },
				{ value: 'action', text: 'Action', width: '15%', sortable: false }
			]
		},
		tableData () {
			return this.apiUsers.filter(({ username, apiKeys }) => {
				let check = true
				if (systemUserTextSearchQuery.value) check = username.toLowerCase().includes(systemUserTextSearchQuery.value.toLowerCase())
				if (apiKeyNameFilter.value !== null) check = check && apiKeys.some(({ apiKeyName }) => apiKeyName.toLowerCase().includes(apiKeyNameFilter.value.toLowerCase()))
				return check
			}).map(apiUser => ({
				...apiUser,
				apiKeyIds: apiUser.apiKeys.map(({ apiKeyId }) => apiKeyId),
				apiKeyNames: apiUser.apiKeys.map(({ apiKeyName }) => apiKeyName).join(', '),
				brandNames: apiUser.brandIds.map(brandId => brandOptions.value.find(({ value }) => brandId === value)?.text).filter(name => name)
			}))
		},
		filterByApiKeyOptions () {
			return [
				{ value: null, text: 'View All' },
				...[...new Set(this.apiUsers.flatMap(({ apiKeys }) => apiKeys.map(({ apiKeyName }) => apiKeyName)))].sort((a, b) => a.localeCompare(b))
			]
		},
		fullPermission () {
			return this.productAreaPermission(SYSTEM_USERS_MODULE_FULL_PERMISSIONS)
		}
	},
	created () {
		this.loadApiUsers()
	},
	methods: {
		async loadApiUsers () {
			const { apiUsers } = await getApiUsers()
			this.apiUsers = apiUsers
		},
		createApiUser () {
			this.$router.push({ name: ADMIN_PORTAL_API_MANAGE_USER })
		},
		editApiUser (apiUserToEdit) {
			this.$router.push({
				name: ADMIN_PORTAL_API_MANAGE_USER,
				params: {
					apiUserToEdit
				}
			})
		},
		async removeApiUser () {
			await deleteApiUser(this.apiUserToRemove.userId)
			this.apiUserToRemove = null
			await this.loadApiUsers()
			showSnackbar('This API User has been removed')
		},
		clearFilters () {
			changeApiKeyNameFilter(null)
		}
	}
}
</script>
