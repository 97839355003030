<template>
	<ViewLayout>
		<template #header-title>
			API Users
		</template>
		<template #header-caption>
			Add and manage api users
		</template>
		<template #content>
			<slot name="content" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</ViewLayout>
</template>
<script>
import ViewLayout from '../../../../../../../shared/layouts/view-layout.vue'
export default {
	components: { ViewLayout }
}
</script>
